import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  categories: []
})

// getters
const getters = {
  listCategories(state) {
    return state.categories;
  }
}

// mutations
const mutations = {
  list(state, categories) {
    state.categories = categories
  },
  delete(state, category) {
    state.categories = state.categories.filter(elem => elem.id != category)
  },
  add(state, category) {
    state.categories.push(category)
  },
  edit(state, category) {
    let find = state.categories.find(elem => elem.id == category.id)
    Object.assign(find, category)
  }
}

// actions
const actions = {
  getCategories(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('categories').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  addCategory(context, category) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('categories', category).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  editCategory(context, category) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.put(`categories/${category.id}`, category).then(response => {
      context.commit('edit', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteCategory(context, category) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`categories/${category}`).then(response => {
      context.commit('delete', category)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  getProductCategories(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('categories/products').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  getApplicationCategories(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('categories/applications').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  screws: []
})

// getters
const getters = {
  listScrews(state) {
    return state.screws;
  }
}

// mutations
const mutations = {
  list(state, screws) {
    state.screws = screws
  },
  delete(state, screw) {
    state.screws = state.screws.filter(elem => elem.id != screw)
  },
  add(state, screw) {
    state.screws.push(screw)
  },
  edit(state, screw) {
    let find = state.screws.find(elem => elem.id == screw.id)
    Object.assign(find, screw)
  }
}

// actions
const actions = {
  getScrews(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('screws').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  addScrew(context, screw) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('screws', screw).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  editScrew(context, screw) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.put(`screws/${screw.id}`, screw).then(response => {
      context.commit('edit', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteScrew(context, screw) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`screws/${screw}`).then(response => {
      context.commit('delete', screw)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

<template>
  <div>
    <md-dialog-alert :md-active.sync="show" md-title="Notificación" :md-content="status" md-confirm-text="Ok"/>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      status: '',
      show: false
    }
  },
  sockets: {
    MESSAGES_SIGNAL_RECEIVED(value) {
      this.show = true
      if(value) {
        this.status = 'Nuevo evento registrado'
      } else {
        this.status = 'Error en operación, registro duplicado o permisos inexistentes. Favor intente nuevamente'
      }
    }
  }
}
</script>

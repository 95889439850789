import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  references: []
})

// getters
const getters = {
  listReferences(state) {
    return state.references;
  }
}

// mutations
const mutations = {
  list(state, references) {
    state.references = references
  },
  add(state, reference) {
    state.references.push(reference)
  },
  delete(state, reference) {
    state.references = state.references.filter(elem => elem.id != reference)
  },
  edit(state, reference) {
    let find = state.references.find(elem => elem.id == reference.id)
    Object.assign(find, reference)
  }
}

// actions
const actions = {
  getReferences(context, id) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get(`reference/${id}/products`).then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  addReference(context, reference) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('reference', reference).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      console.log('err', err)
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteReference(context, id) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`reference/${id}`).then(response => {
      context.commit('delete', id)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  brands: []
})

// getters
const getters = {
  listBrands(state) {
    return state.brands;
  }
}

// mutations
const mutations = {
  list(state, brands) {
    state.brands = brands
  },
  delete(state, brand) {
    state.brands = state.brands.filter(elem => elem.id != brand)
  },
  add(state, brand) {
    state.brands.push(brand)
  },
  edit(state, brand) {
    let find = state.brands.find(elem => elem.id == brand.id)
    Object.assign(find, brand)
  }
}

// actions
const actions = {
  getBrandsApp({commit}) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('brandsApp').then(response => {
        commit('list', response.data.data)
        resolve()
      })
    })
  },
  addBrandApp({commit}, brand) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('brandsApp', brand).then(response => {
      commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  editBrandApp({commit}, brand) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.put(`brandsApp/${brand.id}`, brand).then(response => {
      commit('edit', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteBrandApp({commit}, brand) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`brandsApp/${brand}`).then(response => {
      commit('delete', brand)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

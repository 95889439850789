import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'
import Vue from 'vue'

Vue.use(VueRouter)

// instance
const router = new VueRouter({
  routes
})

// middleware
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user-token')
  if(to.meta.requiresAuth == true) {
    if(!loggedIn) {
      next({path: '/login'})
      return
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  products: [],
  product: {}
})

// getters
const getters = {
  listProducts(state) {
    return state.products;
  },
  listProduct(state) {
    return state.product;
  }
}

// mutations
const mutations = {
  list(state, products) {
    state.products = products
  },
  item(state, product) {
    state.product = product
  },
  delete(state, product) {
    state.products = state.products.filter(elem => elem.id != product)
  },
  add(state, product) {
    state.products.push(product)
  },
  edit(state, product) {
    let find = state.products.find(elem => elem.id == product.id)
    Object.assign(find, product)
  }
}

// actions
const actions = {
  getProducts(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('products').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  getProduct(context, id) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get(`products/${id}`).then(response => {
        context.commit('item', response.data.data)
        resolve()
      })
    })
  },
  addProduct(context, product) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('products', product).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  editProduct(context, product) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.put(`products/${product.id}`, product).then(response => {
      context.commit('edit', response.data.data)
      // this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      console.log('err', err)
      // this._vm.$socket.client.emit('event', false)
    })
  },
  deleteProduct(context, product) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`products/${product}`).then(response => {
      context.commit('delete', product)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

<template>
  <div class="page-container">
    <md-app md-mode="reveal">
      <md-app-toolbar class="md-primary filtroni">
        <span class="md-title">
          <img src="@/assets/filtroni.webp" class="logo" /> 
        </span>
        <Alert />
      </md-app-toolbar>
      <md-app-drawer md-permanent="clipped" v-if="show">
        <Menu />
      </md-app-drawer>
      <md-app-content>
        <router-view @show="showMenu"/>
      </md-app-content>
    </md-app>
  </div>
</template>
<script>
import Alert from '@/components/Common/Alert'
import Menu from '@/components/Common/Menu'
import {mapGetters, mapActions} from 'vuex'
export default {
  name: "App",
  components: {
    Alert, Menu
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters({authenticated: 'auth/getAuthenticationState'}),
  },
  methods: {
    ...mapActions({logout: 'auth/logout'}),
    showMenu(show) {
      this.show = show
    }
  }
}
</script>

import router from '@/router'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json"
  }
})

// redirecting 401
api.interceptors.response.use(null, error => {
  if(error.response.status == 401) {
    router.push({name: 'Login'})
    return Promise.reject(error)
  }
})

export default api

import VueSocketIOExt from 'vue-socket.io-extended'
import VueMaterial from 'vue-material'
import Paginate from 'vuejs-paginate'
import Vuelidate from 'vuelidate'
import socket from '@/socket'
import router from './router'
import App from './App.vue'
import store from './store'
import Vue from 'vue'

// VueMaterial
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

// Material Icons
import 'material-design-icons/iconfont/material-icons.css'

// custom
import './assets/main.css'

Vue.use(VueSocketIOExt, socket, {store})
Vue.use(VueMaterial)
Vue.use(Vuelidate)

// importing component
Vue.component('paginate', Paginate)

Vue.config.productionTip = false

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined") {
      console.error(err);
    }
  }
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  applications: [],
  application: {}
})

// getters
const getters = {
  listApplications(state) {
    return state.applications;
  },
  listApplication(state) {
    return state.application;
  }
}

// mutations
const mutations = {
  list(state, applications) {
    state.applications = applications
  },
  item(state, application) {
    state.application = application
  },
  delete(state, application) {
    state.applications = state.applications.filter(elem => elem.id != application)
  },
  add(state, application) {
    state.applications.push(application)
  }
}

// actions
const actions = {
  getApplications(context, id) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get(`application/${id}/country`).then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  addApplication(context, application) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post(`application/${application.application_id}/country`, application).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteApplication(context, id) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`application/country/${id}`).then(response => {
      context.commit('delete', id)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

import auth from './auth'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  country: []
})

// getters
const getters = {
  listCountries(state) {
    return state.country;
  }
}

// mutations
const mutations = {
  list(state, country) {
    state.country = country
  },
  delete(state, country) {
    state.country = state.country.filter(elem => elem.id != country)
  },
  add(state, country) {
    state.country.push(country)
  },
  edit(state, country) {
    let find = state.country.find(elem => elem.id == country.id)
    Object.assign(find, country)
  }
}

// actions
const actions = {
  getCountries(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
      http.get('country').then(response => {
        context.commit('list', response.data.data)
        resolve()
      })
    })
  },
  addCountry(context, country) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.post('country', country).then(response => {
      context.commit('add', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  editCountry(context, country) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.put(`country/${country.id}`, country).then(response => {
      context.commit('edit', response.data.data)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
  deleteCountry(context, country) {
    http.defaults.headers['Authorization'] = `Bearer ${auth.state().token}`
    http.delete(`country/${country}`).then(response => {
      context.commit('delete', country)
      this._vm.$socket.client.emit('event', true)
    }).catch(err => {
      this._vm.$socket.client.emit('event', false)
    })
  },
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

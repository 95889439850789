import router from '@/router'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  token: localStorage.getItem('user-token') || '',
  authenticated: false,
})

// getters
const getters = {
  getToken(state) {
    return state.token;
  },
  getAuthenticationState(state) {
    return state.authenticated;
  }
}

// mutations
const mutations = {
  storeKey(state, token) {
    state.token = token
    localStorage.setItem('user-token', token)
    state.authenticated = true
  },
  deleteKey(state) {
    state.token = null
    state.authenticated = false
    localStorage.removeItem('user-token')
  }
}

// actions
const actions = {
  authenticate(context, user) {
    return new Promise((resolve, reject) => {
      http.post('login', user).then(response => {
        context.commit('storeKey', response.data.data.access_token)
        resolve()
      }).catch((err) => {
        reject()
      })
    })
  },
  logout(context) {
    return new Promise((resolve, reject) => {
      http.defaults.headers['Authorization'] = `Bearer ${context.state.token}`
      http.post('logout').then(response => {
        context.commit('deleteKey')
        resolve()
      })
    })
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state
}

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/marcas',
    name: 'Brands',
    component: () => import('../views/Brands.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categorias',
    name: 'Categories',
    component: () => import('../views/Categories.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roscas',
    name: 'Screws',
    component: () => import('../views/Screws.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/referencias',
    name: 'References',
    component: () => import('../views/References.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mercados',
    name: 'Countries',
    component: () => import('../views/Countries.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productos',
    name: 'Products',
    component: () => import('../views/Products/Products.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productos/:id',
    name: 'ShowProduct',
    component: () => import('../views/Products/ShowProduct.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/productos/:id/edit',
    name: 'EditProduct',
    component: () => import('../views/Products/EditProduct.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aplicaciones',
    name: 'Applications',
    component: () => import('../views/Applications/Applications.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/aplicaciones/:id',
    name: 'ShowApplication',
    component: () => import('../views/Applications/ShowApplication.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: {name: 'Home'},
  }
]

import router from '@/router'
import http from '@/http'
import Vuex from 'vuex'
import Vue from 'vue'

// state
const state = () => ({
  signal: false

})

// getters
const getters = {
  getSignal(state) {
    return state.signal;
  }
}

// mutations
const mutations = {
  SOCKET_MESSAGES_SIGNAL_RECEIVED(state, status=true) {
    state.signal = status
  }
}

export default {
  namespaced: true,
  mutations,
  getters,
  state
}

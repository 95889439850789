import applicationCountries from './modules/applicationCountries'
import applicationProducts from './modules/applicationProducts'
import applicationBrands from './modules/applicationBrands'
import createPersistedState from 'vuex-persistedstate'
import productScrews from './modules/productScrews'
import applications from './modules/applications'
import references from './modules/references'
import categories from './modules/categories'
import countries from './modules/countries'
import brandsApp from './modules/brandsApp'
import messages from './modules/messages'
import products from './modules/products'
import screws from './modules/screws'
import brands from './modules/brands'
import auth from './modules/auth'

import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

// const dataState = createPersistedState({
//   paths: ['auth.authenticated']
// })

export default new Vuex.Store({
  modules: {
    applicationCountries,
    applicationProducts,
    applicationBrands,
    productScrews,
    applications,
    references,
    categories,
    countries,
    brandsApp,
    messages,
    products,
    screws,
    brands,
    auth
  },
  // plugins: [dataState]
})

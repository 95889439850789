<template>
  <div class="menu-fix">
    <md-toolbar class="md-transparent" md-elevation="0">
      <span class="md-headline">Menú principal</span>
      <span class="md-caption">Opciones disponibles</span>
    </md-toolbar>
    <md-list>
      <md-list-item>
        <md-icon>home</md-icon>
        <router-link :to="{name: 'Home'}" class="md-list-item-text">Inicio</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>branding_watermark</md-icon>
        <router-link :to="{name: 'Brands'}" class="md-list-item-text">Marcas</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>bookmark</md-icon>
        <router-link :to="{name: 'Categories'}" class="md-list-item-text">Categorías</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>store</md-icon>
        <router-link :to="{name: 'Products'}" class="md-list-item-text">Productos</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>add</md-icon>
        <router-link :to="{name: 'Screws'}" class="md-list-item-text">Roscas</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>widgets</md-icon>
        <router-link :to="{name: 'Applications'}" class="md-list-item-text">Aplicaciones</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>swap_horiz</md-icon>
        <router-link :to="{name: 'References'}" class="md-list-item-text">Referencias</router-link>
      </md-list-item>
      <md-list-item>
        <md-icon>map</md-icon>
        <router-link :to="{name: 'Countries'}" class="md-list-item-text">Países/Mercados</router-link>
      </md-list-item>
      <md-divider class="margin-fix"></md-divider>
      <md-subheader class="margin-fix">Sesión</md-subheader>
      <md-list-item @click="logout">
        <md-icon>lock</md-icon>
        <a class="md-list-item-text">Cerrar Sesión</a>
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({path: '/login'})
      })
    }
  }
}
</script>
